var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search...","type":"text"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)])],1)],1),_c('b-table',{ref:"refRefurbishedProductListTable",staticClass:"position-relative",attrs:{"id":"RefurbishProductListTableId","items":_vm.allItems,"responsive":"","fields":_vm.refurbishTableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(parseInt((_vm.pagination.currentPage - 1) * _vm.pagination.perPage) + data.index + 1)+" ")]}},{key:"cell(id)",fn:function(data){return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
            name: `product-details`,
            query: { id: data.item.prod_id },
          }},on:{"click":function($event){return _vm.$router.push({
              name: `product-details`,
              query: { id: data.item.prod_id },
            })}}},[_vm._v(" "+_vm._s(data.item.prod_auto_id)+" ")])]}},{key:"cell(item)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.prod_type.item_type_name)+" ")])])]}},{key:"cell(designer)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.prod_designer)+" ")])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.newStatus( data.item.prod_published, data.item.prod_status_save_type, data.item.prod_status, "refurbish" ))+" ")])]}},{key:"cell(service)",fn:function(data){return [_c('div',{key:data},[_vm._v(_vm._s(data.item.prod_customisation))])]}},{key:"cell(date_submitted)",fn:function(data){return [_c('div',{key:data},[_vm._v(" "+_vm._s(_vm.getHumanDate(data.item.prod_created_at))+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{key:data,staticClass:"mx-1",attrs:{"icon":"EyeIcon","size":"16"}})],1)]}}])}),_c('b-pagination',{attrs:{"aria-controls":"RefurbishProductListTableId","total-rows":_vm.allItems.length,"per-page":_vm.pagination.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }