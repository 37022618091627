export const sellStatus = [
    {
        status_code: 1,
        status_message: "Under Review",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 2,
        status_message: "Review Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 3,
        status_message: "Waiting Seller Response",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 4,
        status_message: "Pre-Quote Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 5,
        status_message: "Item Pickup",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 6,
        status_message: "Pickup Scheduled",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 7,
        status_message: "Item Received",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 8,
        status_message: "Final Authentication",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 9,
        status_message: "Authentication Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 10,
        status_message: "Final Quotation",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 11,
        status_message: "Quotation Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 12,
        status_message: "Approved For Listing",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 13,
        status_message: "Listing Under Process",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 14,
        status_message: "Item Live",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 15,
        status_message: "Item Sold",
        status_icon: "MonitorIcon" 
    }
]