import { DownloadIcon } from "vue-feather-icons";
import axiosInstance from "./Api";

export default {

  async getSingleProductDetails(payload) {
    let response = await axiosInstance().get(`/product/single/${payload}`);
    return response;
  },

  async downloadFile(name) {
    let response = await axiosInstance().get(`/product/downloadImage/${name}`);
    return response;
  },

  async getSellerItems(uid, type) {
    let response = await axiosInstance().get(`/product/all/${uid}/${type}`);
    return response;
  },

  async SaveProduct(payload) {
    let response = await axiosInstance().post("/product/save", payload);
    return response;
  },

  async getAllItems() {
    let response = await axiosInstance().get(`/product/`);
    return response;
  },

  async getSellItems() {
    let response = await axiosInstance().get(`/product/by/sell`);
    return response;
  },

  async getRefurbishItems() {
    let response = await axiosInstance().get(`/product/by/refurbish`);
    return response;
  },

  async updateStatus(payload) {
    let response = await axiosInstance().post("/product/updateStatus", payload);
    return response;
  },

  async updateShippingStatus(payload) {
    let response = await axiosInstance().post("/product/updateShippingStatus", payload);
    return response;
  },

  async addProductComment(payload) {
    let response = await axiosInstance().post("/product/addProductComment", payload);
    return response;
  },

  async getComments(uid){
    let response = await axiosInstance().get(`/product/status/getComments/${uid}`);
    return response;
  },

  async updateRefurbishStatus(payload) {
    let response = await axiosInstance().post("/product/updateRefurbishStatus", payload);
    return response;
  },

  async updateRefurbishComment(payload) {
    let response = await axiosInstance().post("/product/updateRefurbishComment", payload);
    return response;
  },

  async getRefurbishComment(payload) {
    let response = await axiosInstance().post("/product/getRefurbishComment", payload);
    return response;
  },

  async syncProductsWithWoocom(payload) {
    let response = await axiosInstance().get(`/product/syncProductsWithWoocom`);
    return response;
  },

  async updateSellComment(payload) {
    let response = await axiosInstance().post("/product/updateSellComment", payload);
    return response;
  },

  async getSellComment(payload) {
    let response = await axiosInstance().post("/product/getSellComment", payload);
    return response;
  },


  async listAllSellItemComments(payload) {
    let response = await axiosInstance().post("/product/listAllSellItemComments", payload);
    return response;
  },

  async updateActiveInactive(payload) {
    let response = await axiosInstance().post("/product/updateActiveInactive", payload);
    return response;
  },

  async shareItemDetails(payload) {
    let response = await axiosInstance().post("/product/shareItemDetails", payload);
    return response;
  },

  async updateRefDateTime(payload) {
    let response = await axiosInstance().post("/product/updateRefDateTime", payload);
    return response;
  },

  async updateSellDateTime(payload) {
    let response = await axiosInstance().post("/product/updateSellDateTime", payload);
    return response;
  },

};
