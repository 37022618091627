export const refurbishStatus = [
    {
        status_code: 1,
        status_message: "Under Review",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 2,
        status_message: "Item Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 3,
        status_message: "Item Approved",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 4,
        status_message: "You Rejected Pre-Quote",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 5,
        status_message: "Accepted, Make Payment",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 6,
        status_message: "Item Pickup",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 7,
        status_message: "Item Pickedup",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 8,
        status_message: "Item Received At Revivify",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 9,
        status_message: "Under Inspection", //2 Step Authentication
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 10,
        status_message: "Inspection Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 11,
        status_message: "Inspection Approved ", //2 step authentication
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 12,
        status_message: "Final Quote. Rejected",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 13,
        status_message: "Final Quote. Accepted",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 14,
        status_message: "Under Refurbishment",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 15,
        status_message: "Reburbish complete",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 16,
        status_message: "Item on the Way",
        status_icon: "MonitorIcon" 
    },
    {
        status_code: 17,
        status_message: "Item Received",
        status_icon: "MonitorIcon" 
    }
]